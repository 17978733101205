import { daysOfTheWeek, monthsOfTheYear } from "./constants";
import { BOOKING_TIME_ADD } from "my-shared-utils";


// returns format "Friday, 18 August" as an example
export const teaseOutDateElementsLocal = (date) => {
  const newDate = new Date(date);
  const formattedDate =
    daysOfTheWeek[newDate.getDay()] +
    ", " +
    newDate.getDate() +
    " " +
    monthsOfTheYear[newDate.getMonth()];
  return formattedDate;
};

// Returns startTime - endTime in format "06:00 - 10:15" as an example
export const timeFixerLocal = (dateTime, duration, endTime) => {
  const localDate = new Date(dateTime);
  const localDateStart = localDate.toTimeString();
  const end = new Date(dateTime);
  end.setMinutes(end.getMinutes() + duration * 60);
  let localDateEnd = end.toTimeString();
  if (endTime) {localDateEnd = new Date(endTime).toTimeString()}
  const timeString = `${localDateStart.slice(0,5)} - ${localDateEnd.slice(0,5)}`
  return timeString;
};

// creates a date object with the selected date, hours, and mins
export const processDateLocal = (date, hours, mins) => {
  const freshDate = new Date(date);
  freshDate.setHours(hours);
  freshDate.setMinutes(mins);
  return freshDate;
};