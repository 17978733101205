const BOOKING_TIME_ADD = {
  PRE_RECORD_MINUTES : 15,
  POST_RECORD_MINUTES : 15,
  MINIMUM_EVENT_TIME_HOURS : 0.5,
  PRE_SEARCH_EVENT_EXTEND_TIME_HOURS : 15,
  POST_SEARCH_EVENT_EXTEND_TIME_HOURS : 15,
  MAXIMUM_BOOKED_AHEAD_IN_MONTHS : 12,
}

const EMAIL_CONFIRMATIONS_TO_DEVELOPER_ONLY = false;
const CURRENT_DEVELOPER = "jasonstelzel@gmail.com";
const CURRENT_DEVELOPER_SYS01 = "jasonstelzel@gmail.com";
const CURRENT_DEVELOPER_SYS02 = "jasonstelzel@gmail.com";
const CURRENT_DEVELOPER_SYS03 = "jasonstelzel@gmail.com";
// plus addressing not functioning correctly -- email not arriving
// const CURRENT_DEVELOPER_SYS01 = "jasonstelzel+devgooglesystem1@gmail.com";
// const CURRENT_DEVELOPER_SYS02 = "jasonstelzel+devgooglesystem2@gmail.com";
// const CURRENT_DEVELOPER_SYS03 = "jasonstelzel+devgooglesystem3@gmail.com";


const HOUR_IN_MINUTES = 60;
const MINUTE_IN_SECONDS = 60;
const SECOND_IN_MILLISECONDS = 1000;


/**
 * Convert hours to milliseconds.
 * @param {number} hours - The number of hours to convert to milliseconds.
 * @returns {number} - The equivalent time in milliseconds.
 */
const hoursToMilliSeconds = (hours) => {
  if (typeof hours !== 'number') {
    throw new Error("Invalid argument: hours should be a number");
  }
  return hours * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS;
};

/**
 * Convert hours to minutes.
 * @param {number} hours - The number of hours to convert to minutes.
 * @returns {number} - The equivalent time in minutes.
 */
const hoursToMinutes = (hours) => {
  if (typeof hours !== 'number') {
    throw new Error("Invalid argument: hours should be a number");
  }
  return hours * HOUR_IN_MINUTES;
};

/**
 * Convert minutes to milliseconds.
 * @param {number} minutes - The number of minutes to convert to milliseconds.
 * @returns {number} - The equivalent time in milliseconds.
 */
const minutesToMilliSeconds = (minutes) => {
  if (typeof minutes !== 'number') {
    throw new Error("Invalid argument: minutes should be a number");
  }
  return minutes * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS;
};

const ETemails = {
  "Amos Tse": "atse83@bloomberg.net",
  "Brad Crosby": "bcrosby9@bloomberg.net",
  "Christopher Morrissey": "cmorrissey20@bloomberg.net",
  "Dan Lock": "dlock13@bloomberg.net",
  "Jen Wright": "jwright397@bloomberg.net",
  "Jordan Moll": "jmoll2@bloomberg.net",
  "Joshua Roberts": "jroberts397@bloomberg.net",
  "Julian Gomez": "jgomez235@bloomberg.net",
  "Nazy Zakaria": "nzakaria3@bloomberg.net",
  "Rashid Ramkissoon": "rramkissoon3@bloomberg.net",
  "Ryan Meyer": "rmeyer114@bloomberg.net",
  "William White": "wwhite74@bloomberg.net",
}

module.exports = {
  HOUR_IN_MINUTES,
  MINUTE_IN_SECONDS,
  SECOND_IN_MILLISECONDS,
  BOOKING_TIME_ADD,
  EMAIL_CONFIRMATIONS_TO_DEVELOPER_ONLY,
  CURRENT_DEVELOPER,
  CURRENT_DEVELOPER_SYS01,
  CURRENT_DEVELOPER_SYS02,
  CURRENT_DEVELOPER_SYS03,
  hoursToMilliSeconds,
  hoursToMinutes,
  minutesToMilliSeconds,
  ETemails,
};

